<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              預約管理
            </b-breadcrumb-item>

            <b-breadcrumb-item :to="{ name: 'BookingPresetList' }" active>
              預約設定檔列表
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-8 mb-2 font-weight-bold">預約設定檔列表</h4>
        <b-input-group class="col-12 col-xl-4 mb-2">
          <b-form-input v-model="keyword" placeholder="搜尋"></b-form-input>
          <b-input-group-append>
            <b-button @click="fetchBookingPresets"><i class="fa fa-search"></i ></b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
      <div class="d-flex mb-4 mb-xl-2 justify-content-between">
        <div
          class="
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <b-button
            v-if="checkPermission([consts.BOOKING_PRESET_CREATE])"
            class="flex-shrink-0 mb-2 mb-xl-0"
            variant="primary"
            :to="{ name: 'BookingPresetCreate' }"
            ><i class="fa fa-plus"></i>新增預約設定檔</b-button
          >
        </div>
      </div>
      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="bookingPresets"
                :fields="fields"
              >
                <template #cell(is_enabled)="data">
                  <b-badge v-if="data.item.config.is_enabled == true" variant="success">啟用</b-badge>
                  <b-badge v-if="data.item.config.is_enabled == false" variant="secondary">停用</b-badge>
                </template>
                <template #cell(actions)="data">
                  <b-button
                    class="mr-1"
                    v-if="checkPermission([consts.BOOKING_PRESET_EDIT])"
                    variant="inverse-warning"
                    :to="{ name: 'BookingPresetEdit', params: { id: data.item.id }}"
                  >
                    <span class="mdi mdi-lead-pencil"></span> 編輯
                  </b-button>
                  <b-button
                    class="mr-1"
                    v-if="checkPermission([consts.BOOKING_LIST])"
                    variant="inverse-success"
                    :to="{ name: 'BookingList', params: { id: data.item.id } }"
                  >
                    <span class="mdi mdi-format-list-bulleted"></span> 預約列表
                  </b-button>
                  <b-button
                    class="mr-1"
                    v-if="checkPermission([consts.BOOKING_PRESET_DELETE])"
                    variant="inverse-danger"
                    @click="deleteBookingPreset(data.item)"
                  >
                    <span class="mdi mdi-delete"></span> 刪除
                  </b-button>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
        <div class="d-flex justify-content-center" style="margin-top: 80px">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            @change="fetchBookingPresets"
          ></b-pagination>
        </div>
      </b-card-text>
    </b-card>
  </div>

</template>

<script>
import * as consts from "@/consts";
import PermissionChecker from '@/utils/PermissionChecker';
import { format } from "date-fns";
import bookingApi from "@/apis/booking";
import { mapState } from "vuex";

export default {
  data() {
    return {
      consts: consts,
      bookingPresets: [],
      keyword: '',
      showLoading: false,
      fields: [
        { key: 'name', label: '預約名稱', sortable: true },
        { key: 'is_enabled', label: '是否開放預約', sortable: false },
        { key: 'bookings_count', label: '目前預約筆數', sortable: true },
        {
          key: 'created_at',
          label: '建立時間',
          sortable: true,
          formatter: (value) => {
            return format(new Date(value), 'yyyy-MM-dd HH:mm:ss');
          },
        },
        { key: 'actions', label: '管理' },
      ],
      currentPage: 1,
      perPage: 20,
      totalRows: 0,
    }
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
  },
  mounted() {
    this.fetchBookingPresets();
  },
  methods: {
    checkPermission(permissions) {
      const checker = new PermissionChecker;
      return checker.check(permissions);
    },
    async fetchBookingPresets() {
      try {
        this.showLoading = true;
        let response = await bookingApi.getBookingPresets({
          keyword: this.keyword,
          per_page: this.perPage,
          page: this.currentPage,
        })
        this.bookingPresets = response.data.data;
        this.totalRows = response.data.data.total;
      } catch (error) {
        console.error(error);
        this.$swal('錯誤', '讀取列表失敗', 'error');
      } finally {
        this.showLoading = false;
      }
    },
    async deleteBookingPreset(bookingPreset) {
      try {
        this.showLoading = true;
        const result = await this.$swal({
          title: "刪除預約設定檔",
          text: `是否要刪除此預約設定檔？將會連同此預約設定的所有預約記錄一起刪除`,
          type: "warning",
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: "取消",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "移除",
          reverseButtons: true,
        })

        if (! result.value) {
          return
        }

        await bookingApi.deleteBookingPreset(bookingPreset.id);
        this.$swal('刪除成功', '', 'success');
        await this.fetchBookingPresets();
      } catch (error) {
        console.error(error);
        this.$swal('刪除失敗', '', 'error');
      } finally {
        this.showLoading = false;
      }
    },
  }
}
</script>
